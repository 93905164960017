<template>
  <div id="order-view-cancellation-policies">
    <b-overlay :show="quoteLoading">
      <b-card no-body>
        <b-card-body :class="['invoice-padding', { 'mt-n5 ': printMode }]">
          <h4>
            {{ $tc('cancellation_policy.title', 2) }}
          </h4>
          <template v-if="quoteSelected.quote.lines.some(line => line.conditions.length)">
            <b-list-group v-for="line in quoteSelected.quote.lines" :key="line.id" flush>
              <b-list-group-item v-if="line.conditions.length">
                <h6 class="mb-25">{{ line.name }}</h6>
                <div v-for="condition in line.conditions" :key="condition.id">
                  <span v-if="condition.ifClause" :class="{ 'text-nowrap': $store.getters[`app/mdAndUp`] }">
                    {{ condition.ifClause | enumTranslate('cancellation_policy_condition_if') }},
                    <strong class="condition-rule"> {{ condition.ruleClause | enumTranslate('cancellation_policy_condition_rule') }}</strong>
                    {{ $t('cancellation_policy.condition.with_rate') }}
                    <b-badge pill variant="light-primary"> {{ condition.priceRate }}%</b-badge>
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
          <p v-else>{{ $t('cancellation_policy.no_cancellation_policies') }}</p>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewCancellationPolicies',

  props: {
    printMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteLoading']),
  },
}
</script>

<style lang="scss" scoped>
.condition-rule {
  color: #28c76f;
}
</style>
